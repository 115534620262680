import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import WistiaPopoverVideo from '../../../components/WistiaPopoverVideo';
import FreeTrialSignup from '../../../components/FreeTrialSignup';

import './style.scss';

const autobranding = () => {
    return (
        <Layout navTheme="light">
            <Seo
                title="Automatically branded email library | GlassHive"
                description="Don’t have time to design emails? With GlassHive, you gain access to an entire library of fully branded email templates. Check it out!"
            />
            <div className="autobranding-page">
                <Container className="container-1">
                    <Row className="center">
                        <Col className="centered-text">
                            <h1 className="white-text max-width-1100 margin-auto">
                                Auto-branding gets you marketing faster
                            </h1>
                            <p className="hero-subtext white-text">
                                Save time and streamline your marketing process
                                with GlassHive’s Auto Branding feature
                            </p>
                        </Col>
                    </Row>
                    <img
                        className="white-divider"
                        src={require('../../../images/features/lead-scrubbing/white-divider.svg')}
                        alt="white divider"
                    />
                </Container>
                <Container className="container-2">
                    <Row>
                        <Col className="three-fifth video-column">
                            <WistiaPopoverVideo id={'s6wn17isma'}>
                                <img
                                    className="thumbnail"
                                    src={require('../../../images/features/autobranding/thumbnail.png')}
                                    alt="Auto branding video thumbnail"
                                />
                            </WistiaPopoverVideo>
                        </Col>
                        <Col className="two-fifth">
                            <h4 className="centered-mobile">
                                Don’t have time to design emails?
                            </h4>
                            <p>
                                Add your business logo and company colors with
                                just a few clicks and never worry about it
                                again.
                            </p>
                            <p>
                                Each time you want to send out a new campaign,
                                the email will be auto-branded with your
                                selection saving you time and streamlining your
                                marketing process
                            </p>
                            <p>
                                Check out how simple it is to create and send
                                these emails by watching the video.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-3">
                    <Row>
                        <Col className="two-fifth">
                            <h4 className="centered-mobile">
                                Update your brand once
                            </h4>
                            <p>
                                You won’t have to worry about spending hours
                                changing colors, logos, and contact info each
                                time you create a new email.
                            </p>
                            <h4 className="centered-mobile">
                                Get marketing fast
                            </h4>
                            <p>
                                With auto-branding, all you’ll need to worry
                                about is copy should you choose to. With
                                GlassHive, you have access to a library of
                                written emails that can be instantly converted
                                to your brand.
                            </p>
                        </Col>
                        <Col className="three-fifth form-column">
                            <FreeTrialSignup titleText="Get on board with GlassHive and sign up for a free subscription!" />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default autobranding;
